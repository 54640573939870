import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Button from "../../components/Button";
import { Link } from 'react-router-dom'
import Aos from "aos";
import "aos/dist/aos.css";

const Nuestros = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className={styles.about}>
      <div className={styles.container}>
        <Title dos="fade-left" className={styles.title}>
          Nuestros
        </Title>
        <Title dos="fade-left" className={styles.title__}>
          valores
        </Title>
        <div className={styles.box}>
          <div data-aos="fade-left">
            <div className={styles.item}>
              <Title className={styles.title__small}>LA COMIDA</Title>
              <Title className={styles.title__small}>EL ROCK Y</Title>
              <Title className={styles.title__small__}>A NUESTRA </Title>
              <Title className={styles.title__small__}>MANERA</Title>
              <p className={styles.p}>
                Porque conocer y vivir <br /> nuestro estilo nos hace <br />{" "}
                únicos.
              </p>
            </div>
            <div className={styles.item}>
              <Title className={styles.title__small}>CRECEMOS Y</Title>
              <Title className={styles.title__small}>EVOLUCIONAMOS</Title>
              <Title className={styles.title__small__}>TODOS LOS DÍAS</Title>
              <p className={styles.p}>
                Empujamos nuestros límites <br /> incluso más lejos de lo que
                están.
              </p>
            </div>
            <div className={styles.item}>
              <Title className={styles.title__small}>HACEMOS</Title>
              <Title className={styles.title__small__}>EQUIPO</Title>
              <p className={styles.p}>
                Porque sabemos que juntos <br /> somos más fuertes.
              </p>
            </div>
          </div>
          <div data-aos="fade-right" className={styles.right}>
            <div className={styles.item}>
              <Title className={styles.title__small}>LA CREATIVIDAD </Title>
              <Title className={styles.title__small}>ES NUESTRA </Title>
              <Title className={styles.title__small__}>MEJOR </Title>
              <Title className={styles.title__small__}>HERRAMIENTA</Title>
              <p className={styles.p}>
                Tomamos decisiones con ingenio <br /> y voluntado.
              </p>
            </div>
            <div className={styles.item}>
              <Title className={styles.title__small}>SOMOS</Title>
              <Title className={styles.title__small}>SUPER</Title>
              <Title className={styles.title__small__}>CHAMBEADORES</Title>
              <p className={styles.p}>
                Trabajamos de manera organizada <br /> y nos mantenemos en
                movimiento.
              </p>
            </div>
            <div className={styles.item}>
              <Link to="/menu">
                <Button className={styles.button}>Ver menú</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nuestros;
