import React, { useEffect } from "react";

import styles from "./styles.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/RockNWok_Moodboard_01.png';
import rwImg1x2 from '../../assets/static/RockNWok_Moodboard_01@2x.png';

import rwImg2 from '../../assets/static/RockNWok_Moodboard_02.png';
import rwImg2x2 from '../../assets/static/RockNWok_Moodboard_02@2x.png';

import rwImg3 from '../../assets/static/RockNWok_Moodboard_03.png';
import rwImg3x2 from '../../assets/static/RockNWok_Moodboard_03@2x.png';

import rwImg4 from '../../assets/static/RockNWok_Moodboard_04.png';
import rwImg4x2 from '../../assets/static/RockNWok_Moodboard_04@2x.png';

import rwImg5 from '../../assets/static/RockNWok_Moodboard_05.png';
import rwImg5x2 from '../../assets/static/RockNWok_Moodboard_05@2x.png';

const Assets = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.lets}>
      <div className={styles.image}>
        <img
          src={rwImg1}
          srcset={rwImg1x2 + ' 2x'}
          height="auto"
          width="1920 "
          alt="Rock and Wok Moodboard"
          data-aos="fade-up"
        />
      </div>
      <div className={styles.image}>
        <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
          height="auto"
          width="1920"
          alt="Rock and Wok Moodboard"
          data-aos="fade-down"
          data-aos-delay="500"
        />
      </div>
      <div className={styles.image}>
        <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
          height="auto"
          width="1920"
          alt="Rock and Wok Moodboard"
          data-aos="fade-left"
          data-aos-delay="1000"
        />
      </div>
      <div className={styles.image}>
        <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
          height="auto"
          width="1920"
          alt="Rock and Wok Moodboard"
          data-aos="fade-rigth"
          data-aos-delay="1500"
        />
      </div>
      <div className={styles.image}>
        <img
              src={rwImg5}
              srcset={rwImg5x2 + ' 2x'}
          height="auto"
          width="1920"
          alt="Rock and Wok Moodboard"
          data-aos="fade"
          data-aos-delay="2000"
        />
      </div>
    </div>
  );
};

export default Assets;
