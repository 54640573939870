import React, { useEffect, useState } from "react";
import Home1 from "../containers/Home";
import About from "../containers/About";
import Lets from "../containers/Lets";
import Slider from "../containers/Slider";
import SliderBrands from "../containers/SliderBrands";
import Nuestros from "../containers/Nuestros";
import Assets from "../containers/Assets";
import Reviews from "../containers/Reviews";
import Cocinamos from "../containers/Cocinamos";
import RockWithUs from "../containers/RockWithUs";
import Sucursales from "../containers/Sucursales";
import {Helmet} from "react-helmet";

import Loading from "../components/Loading";

export default function Home() {
  const [loading, setLoading] = useState(true);

  const handleLoading = () => {
    setLoading(false);
  };
  useEffect(() => {
    if (document.readyState === "complete") {
      setLoading(false);
    } else {
      window.addEventListener("load", handleLoading);
      return () => window.removeEventListener("load", handleLoading);
    }
  }, []);

  return (
    <main>
      <Helmet>
        <title>ROCK N' WOK</title>
        <meta name="description" content="Rock n’ Wok es comida oriental y comida china hecha a nuestra manera, que inicia en el año 2010 con la firme convicción de evolucionar y trabajar para trascender juntos." />
      </Helmet>
      <Loading show={loading} />
      <Home1 />
      <About />
      <Lets />
      <Slider />
      <Nuestros />
      <Assets />
      <SliderBrands />
      <Reviews />
      <Cocinamos />
      <RockWithUs />
      <Sucursales title="rockin' spots" />
    </main>
  );
}
