import Menu1 from "../containers/Menu1";
import MenuHeader from "../containers/MenuQr";
import Base from "../containers/BaseQr";
import Wok from "../containers/WokQr";
import LaEspecialidad from "../containers/LaEspecialidadQr";
import Arroces from "../containers/ArrocesQr";
import FullBox from "../containers/FullBoxQr";
import Especialidades from "../containers/EspecialidadesQr";
import NuestrosAcom from "../containers/NuestrosAcomQr";
import RockinSweet from "../containers/RockinSweetQr";
import {Helmet} from "react-helmet";

export default function Menu() {
  return (
    <main>
      <Helmet>
        <title>ROCK N' WOK - Sonata</title>
        <meta name="description" content="Arma tu box con tu base favorita + alguna de nuestras deliciosas especialidades." />
      </Helmet>
      <MenuHeader />
      <Menu1 />
      <Base />
      <LaEspecialidad />
      <Arroces />
      <FullBox />
      <Especialidades id="sonata" />
      <Wok />
      <NuestrosAcom />
      <RockinSweet />
    </main>
  );
}
/* Mapa por filtros */
