import React, { useEffect } from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdArrowDownward } from "react-icons/md";
// import logo from "../../assets/static/RockNWok_Logo_Positivo.svg";
import menuqr from "../../assets/static/MenuQr.svg";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";

import "aos/dist/aos.css";
const Base = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.container}>
      {/*  <img src={logo} alt="" /> */}
      <div
        style={{
          backgroundImage: `url('${menuqr}')`,
          padding: "100px 0",
          marginBottom: "10px",
        }}
      >
        <Title dos="fade-up" className={styles.title}>
          MENÚ
        </Title>
        <Title dos="fade-up" className={styles.title__}>
          SONATA
        </Title>
      </div>
      <Subtitle dos="fade-up" className={styles.subtitle2}>
        <BsFillTelephoneFill /> 222 295 4500
      </Subtitle>
      <Subtitle dos="fade-up" className={styles.subtitle2}>
        <AiOutlineWhatsApp /> 222 928 8044
      </Subtitle>
      <Subtitle dos="fade-up" className={styles.subtitle}>
        Servicio a domicilio <br />o pick up.
      </Subtitle>
      <div style={{ marginTop: "50px" }}></div>
      <div>
        <div className={styles.arrow}>
          <MdArrowDownward />
        </div>
      </div>
    </div>
  );
};

export default Base;
