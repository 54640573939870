import React from "react";
import styles from "./styles.module.css";

const Button = ({ className, children, onClick, dos }) => (
  <button data-aos={dos} onClick={onClick} className={`${styles.button} ${className}`}>
    {children}
  </button>
);

export default Button;
