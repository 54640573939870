import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/ArmaTuBox_2.1_LaEspecialidad_Orange.png';
import rwImg1x2 from '../../assets/static/ArmaTuBox_2.1_LaEspecialidad_Orange@2x.png';

import rwImg2 from '../../assets/static/ArmaTuBox_2.2_LaEspecialidad_Sesame.png';
import rwImg2x2 from '../../assets/static/ArmaTuBox_2.2_LaEspecialidad_Sesame@2x.png';

import rwImg3 from '../../assets/static/ArmaTuBox_2.3_LaEspecialidad_ZZWok.png';
import rwImg3x2 from '../../assets/static/ArmaTuBox_2.3_LaEspecialidad_ZZWok@2x.png';

import rwImg4 from '../../assets/static/ArmaTuBox_2.4_LaEspecialidad_SpicyBao.png';
import rwImg4x2 from '../../assets/static/ArmaTuBox_2.4_LaEspecialidad_SpicyBao@2x.png';

import rwImg5 from '../../assets/static/ArmaTuBox_2.5_LaEspecialidad_Hunan.png';
import rwImg5x2 from '../../assets/static/ArmaTuBox_2.5_LaEspecialidad_Hunan@2x.png';

import rwImg6 from '../../assets/static/ArmaTuBox_2.6_LaEspecialidad_BeefNBroccoli.png';
import rwImg6x2 from '../../assets/static/ArmaTuBox_2.6_LaEspecialidad_BeefNBroccoli@2x.png';

import rwImg7 from '../../assets/static/ArmaTuBox_2.7_LaEspecialidad_Veggie.png';
import rwImg7x2 from '../../assets/static/ArmaTuBox_2.7_LaEspecialidad_Veggie@2x.png';

import rwImg8 from '../../assets/static/ArmaTuBox_2.8_LaEspecialidad_SweetNSour.png';
import rwImg8x2 from '../../assets/static/ArmaTuBox_2.8_LaEspecialidad_SweetNSour@2x.png';

import rwImg9 from '../../assets/static/ArmaTuBox_2.9_LaEspecialidad_ShrimpBao.png';
import rwImg9x2 from '../../assets/static/ArmaTuBox_2.9_LaEspecialidad_ShrimpBao@2x.png';

const Base = (id) => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.container}>
      <Title dos="fade-up" className={styles.title}>
        especialidades
      </Title>
      <Subtitle dos="fade-up" className={styles.subtitle}>
        Una vez seleccionada tu base, sigue elegir alguna de <br /> nuestras
        deliciosas especialidades:
      </Subtitle>
      <div className={styles.grid}>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              width="150"
              height="150"
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
              alt="La especialidad pollo a la naranja - Orange - Pollo crispy en nuestra salsa de naranja especial"
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Orange
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Pollo crispy en nuestra salsa de naranja especial en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={`${styles.embla__slide__image} ${styles.embla__slide__image__}`} data-aos="fade-up">
            <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
              width="150"
              height="150"
              alt="La especialidad - Sesame - Láminas de pollo, brócoli, ejote, pimiento, zanahoria, cebolla, ajonjolí, salsa dulce oriental en una base de noodles o arroz frito a tu elección."
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Sesame
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Láminas de pollo, brócoli, ejote, pimiento, zanahoria, cebolla,
            ajonjolí, salsa dulce oriental en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={`${styles.embla__slide__image} ${styles.embla__slide__image_}`} data-aos="fade-up">
            <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
              width="150"
              height="150"
              alt="La especialidad - ZZ Wok - Pollo crispy, pimiento, apio, cebolla, ejote, salsa de chiles en una base de noodles o arroz frito a tu elección."
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            ZZ Wok
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Pollo crispy, pimiento, apio, cebolla, ejote, salsa de chiles en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
            src={rwImg4}
            srcset={rwImg4x2 + ' 2x'}
              width="150"
              height="172"
              alt="La especialidad - Spicy Bao - Pollo crispy, cebollín, cacahuate, apio, hojuelas de chile, salsa picante en una base de noodles o arroz frito a tu elección."
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Spicy Bao
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Pollo crispy, cebollín, cacahuate, apio, hojuelas de chile, salsa
            picante en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg5}
              srcset={rwImg5x2 + ' 2x'}
              width="150"
              height="150"
              alt="La especialidad - Hunan - Pollo crispy, zanahoria, cacahuate, tallo de brócoli, nuez de la india, salsa agridulce acaramelada en una base de noodles o arroz frito a tu elección."
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Hunan
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Pollo crispy, zanahoria, cacahuate, tallo de brócoli, nuez de la
            india, salsa agridulce acaramelada en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg6}
              srcset={rwImg6x2 + ' 2x'}
              width="150"
              height="150"
              alt="La especialidad - Beef n' broccoli - Láminas de res, brócoli, cebollín, jengibre, salsa estilo cantonés en una base de noodles o arroz frito a tu elección. "
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Beef n’ broccoli
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Láminas de res, brócoli, cebollín, jengibre, salsa estilo cantonés en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg7}
              srcset={rwImg7x2 + ' 2x'}
              width="150"
              height="150"
              alt="La especialidad - Veggie - Brócoli, zanahoria, ejote, champiñón, un toque de jengibre, salsa de soya en una base de noodles o arroz frito a tu elección. "
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Veggie
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Brócoli, zanahoria, ejote, champiñón, un toque de jengibre, salsa de
            soya en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg8}
              srcset={rwImg8x2 + ' 2x'}
              width="150"
              height="150"
              alt="La especialidad - Sweet n' sour - Pollo crispy, piña, pimiento, salsa agridulce en una base de noodles o arroz frito a tu elección."
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Sweet n’ sour
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Pollo crispy, piña, pimiento, salsa agridulce en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg9}
              srcset={rwImg9x2 + ' 2x'}
              width="150"
              height="172"
              alt="La especialidad - Shrimp Bao - Camarón crispy, cebollín, cacahuate, apio, hojuelas de chile, salsa picante en una base de noodles o arroz frito a tu elección."
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Shrimp bao
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Camarón crispy, cebollín, cacahuate, apio, hojuelas de chile, salsa
            picante en una base de noodles o arroz frito a tu elección.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Base;
