import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import { FaCircle, FaMapMarkerAlt } from "react-icons/fa";
import Aos from "aos";
import "aos/dist/aos.css";
import Map from "./Map";

const Sucursales = ({ title }) => {
  const [branch, setCenter] = useState({
    lat: "19.02737646100325",
    lng: "-98.2371575230361",
    zoom: 7,
  });

  const [active, setActive] = useState("Todos");

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  const handleSetFilter = (filter) => {
    setCenter({
      lat: filter.lat,
      lng: filter.lng,
      zoom: filter.zoom,
    });
    setActive(filter.location);
  };

  return (
    <div className={styles.sucursales} id="sucursales">
      <div className={styles.container}>
        <Title dos="fade-down" className={styles.title}>
          {title}
        </Title>
        <div className={styles.map}>
          <Map
            center={branch.center}
            lat={branch.lat}
            lng={branch.lng}
            zoom={branch.zoom}
          />
        </div>

        <ul className={styles.filters} data-aos="fade-down">
          <li
            className={
              active === "Todos" ? styles.filter__active : styles.filter
            }
            onClick={() =>
              handleSetFilter({
                lat: "19.02737646100325",
                lng: "-98.2371575230361",
                zoom: 7,
                location: "Todos",
              })
            }
          >
            <FaCircle /> Todos
          </li>
          <li
            className={
              active === "Puebla" ? styles.filter__active : styles.filter
            }
            onClick={() =>
              handleSetFilter({
                lat: "19.02737646100325",
                lng: "-98.2371575230361",
                zoom: 13,
                location: "Puebla",
              })
            }
          >
            <FaCircle /> Puebla
          </li>
          <li
            className={
              active === "Queretaro" ? styles.filter__active : styles.filter
            }
            onClick={() =>
              handleSetFilter({
                lat: "20.631690021457743",
                lng: "-100.35781693880061",
                zoom: 13,
                location: "Queretaro",
              })
            }
          >
            <FaCircle /> Querétaro
          </li>
        </ul>

        <div className={styles.box}>
          <div className={styles.box2}>
            <div className={styles.item} data-aos="fade-right">
              <Title className={styles.title__small}>
                <FaMapMarkerAlt />
                EXPLANADA
              </Title>
              <p className={styles.p}>
                Calle Alejandra 512. Local <br /> 25, Santiago Momoxpan, <br />{" "}
                Álvaro Obregón, 72775.
                <br /> Puebla, Pue.
              </p>
              <p className={styles.p2}>T. 222 622 2154</p>
              <p className={styles.p3}>
                <a href="https://goo.gl/maps/XQGDvKPqLRYVr5jN8" target="_blank">
                  Google Maps
                </a>
              </p>
            </div>
            <div className={styles.item} data-aos="fade-down">
              <Title className={styles.title__small}>
                <FaMapMarkerAlt />
                SONATA
              </Title>
              <p className={styles.p}>
                Paseo Ópera 11, Plaza <br /> Adagio. Lomas de <br />
                Angelópolis. San Andrés <br />
                Cholula, Puebla.
              </p>
              <p className={styles.p2}>T. 222 295 4500</p>
              <p className={styles.p3}>
                <a href="https://goo.gl/maps/VEgxNuZm6fKeemmn7" target="_blank">
                  Google Maps
                </a>
              </p>
            </div>
            <div className={styles.item} data-aos="fade-left">
              <Title className={styles.title__small}>
                <FaMapMarkerAlt />
                UPAEP
              </Title>
              <p className={styles.p}>
                Avenida 13 Poniente 2101, <br /> Barrio de Santiago, 72410.{" "}
                <br /> Puebla, Pue.
              </p>
              <p className={styles.p2}>T. 222 278 9112</p>
              <p className={styles.p3}>
                <a href="https://goo.gl/maps/J75UakzcXXnXz9Zp6" target="_blank">
                  Google Maps
                </a>
              </p>
            </div>
          </div>
          <div className={styles.right} data-aos="fade-up">
            <div className={styles.box3}>
              <div className={styles.item}>
                <Title className={styles.title__small}>
                  <FaMapMarkerAlt />
                  VÍA SAN ÁNGEL
                </Title>
                <p className={styles.p}>
                  Vía Atlixcáyotl 1504. Local FF3. Colonia <br /> Reserva
                  Territorial Atlixcáyotl, 72810.
                  <br /> Puebla, Pue.
                </p>
                <p className={styles.p2}>T. 222 225 2049</p>
                <p className={styles.p3}>
                  <a
                    href="https://goo.gl/maps/fBr78X1UFxuKtYWv9"
                    target="_blank"
                  >
                    Google Maps
                  </a>
                </p>
              </div>
            </div>
            <div className={styles.item2}>
              <Title className={styles.title__small2}>
                <FaMapMarkerAlt />
                PASEO QUERÉTARO
              </Title>
              <p className={styles.p}>
                Blvd. Anillo Vial Fray Junípero Serra <br /> 7901. La Purísima,
                76146. Santiago de <br /> Querétaro, Qro.
              </p>
              <p className={styles.p2}>T. 442 628 2052</p>
              <p className={styles.p4}>
                <a href="https://goo.gl/maps/G2mA7jSdbdzN2X1F7" target="_blank">
                  Google Maps
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sucursales;
