import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/Wok_Testimonios.jpg';
import rwImg1x2 from '../../assets/static/Wok_Testimonios@2x.jpg';

import rwImg2 from '../../assets/static/Wok_Testimonios_Flames_01.png';
import rwImg2x2 from '../../assets/static/Wok_Testimonios_Flames_01@2x.png';

import rwImg3 from '../../assets/static/Wok_Testimonios_Flames_02.png';
import rwImg3x2 from '../../assets/static/Wok_Testimonios_Flames_02@2x.png';

import rwImg4 from '../../assets/static/Testimonio_01.png';
import rwImg4x2 from '../../assets/static/Testimonio_01@2x.png';

import rwImg5 from '../../assets/static/Testimonio_02.png';
import rwImg5x2 from '../../assets/static/Testimonio_02@2x.png';

import rwImg6 from '../../assets/static/Testimonio_03.png';
import rwImg6x2 from '../../assets/static/Testimonio_03@2x.png';

const Reviews = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.reviews}>
      <div className={styles.image}>
        <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
          height="800"
          width="1280 "
          alt="Testimonio"
          data-aos="fade-up"
        />
      </div>
      <div className={styles.particles}>
        <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
          height="800"
          width="1280 "
          alt="Testimonio Llamas"
          data-aos="fade-left"
        />
      </div>
      <div className={styles.particles}>
        <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
          height="800"
          width="1280 "
          alt="Testimonio Llamas"
          data-aos="fade-right"
        />
      </div>
      <div className={styles.review} data-aos="fade-right">
        <a
          href="https://www.facebook.com/rockandwok/reviews/?ref=page_internal"
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <p className={styles.p}>
            “El wok de res <br /> estuvo delicioso.
            <br /> La carne muy
            <br /> suave y en buena
            <br />
            porción.”
          </p>
        </a>
        <div className={styles.stars}>
          <img
            src="/Testimonios_Estrellas.svg"
            height="10"
            width="81 "
            alt="Estrellas"
          />
        </div>
        <div className={styles.user}>
          <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
            height="150"
            width="150"
            alt="Testimonio"
          />
        </div>
      </div>
      <div className={styles.review2}>
        <a
          href="https://www.facebook.com/rockandwok/reviews/?ref=page_internal"
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <p className={styles.p}>
            “Me gustó el concepto, el <br /> lugar pero sobre todo el <br />{" "}
            sabor de la comida.”
          </p>
        </a>
        <div className={styles.stars}>
          <img
            src="/Testimonios_Estrellas.svg"
            height="10"
            width="81 "
            alt="Testimonio Estrellas"
          />
        </div>
        <div className={styles.user2}>
          <img
              src={rwImg5}
              srcset={rwImg5x2 + ' 2x'}
            height="150"
            width="150"
            alt="Testimonio"
          />
        </div>
      </div>
      <div className={styles.review3} data-aos="fade-left">
        <a
          href="https://www.facebook.com/rockandwok/reviews/?ref=page_internal"
          target="_blank"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <p className={styles.p}>
            “Súper rico <br /> y excelente <br /> servicio.”
          </p>
        </a>
        <div className={styles.stars}>
          <img
            src="/Testimonios_Estrellas.svg"
            height="10"
            width="81 "
            alt="Testimonio Estrellas"
          />
        </div>
        <div className={styles.user2}>
          <img
              src={rwImg6}
              srcset={rwImg6x2 + ' 2x'}
            height="150"
            width="150"
            alt="Testimonio"
          />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
