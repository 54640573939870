import Contact1 from "../containers/Contact";
import {Helmet} from "react-helmet";

export default function Home() {
  return (
    <main>
      <Helmet>
        <title>ROCK N' WOK - Contacto</title>
        <meta name="description" content="¿Quieres decirnos algo? Estamos para escucharte. Mándanos un e-mail a direccionoperaciones@rockandwok.com" />
      </Helmet>
      <Contact1 />
    </main>
  );
}
