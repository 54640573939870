import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import Title from "../../components/Title";

import Aos from "aos";
import "aos/dist/aos.css";

import { Link } from "react-router-dom";
import useEmblaCarousel from "embla-carousel-react";

import rwImg1 from '../../assets/static/RockNWok_Acompanantes_Rockshiages.png';
import rwImg1x2 from '../../assets/static/RockNWok_Acompanantes_Rockshiages@2x.png';

import rwImg2 from '../../assets/static/RockNWok_Especialidad_Sesame.png';
import rwImg2x2 from '../../assets/static/RockNWok_Especialidad_Sesame@2x.png';

import rwImg3 from '../../assets/static/RockNWok_Bebidas_TedeJazmin.png';
import rwImg3x2 from '../../assets/static/RockNWok_Bebidas_TedeJazmin@2x.png';

import rwImg4 from '../../assets/static/ArmaTuBox_2.3_LaEspecialidad_ZZWok.png';
import rwImg4x2 from '../../assets/static/ArmaTuBox_2.3_LaEspecialidad_ZZWok@2x.png';

import rwImg5 from '../../assets/static/RockNWok_Especialidad_Orange.png';
import rwImg5x2 from '../../assets/static/RockNWok_Especialidad_Orange@2x.png';

import rwImg6 from '../../assets/static/RockNWok_Especialidad_BeefNBroccoli.png';
import rwImg6x2 from '../../assets/static/RockNWok_Especialidad_BeefNBroccoli@2x.png';

const Slider = () => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "center",
    loop: true,
    skipSnaps: false,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );
  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    Aos.init({ duration: 2000 });
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className={styles.container}>
      <Title className={styles.title}>the</Title>
      <Title className={styles.title__}>favs</Title>
      <div className={styles.embla}>
        <div className={styles.embla__viewport} ref={viewportRef}>
          <div className={styles.embla__container}>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <div className={styles.embla__slide__image}>
                    <img
                      src={rwImg1}
                      srcset={rwImg1x2 + ' 2x'}
                      width="230"
                      height="230"
                      alt="Acompañantes - Rockshiages"
                    />

                    <Button className={styles.button__float}>
                      <a
                        href="https://pide.rockandwok.com/rocknwok/new"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        ordenar en línea
                      </a>
                    </Button>
                  </div>
                  <h5 className={styles.h5}>RockShiages</h5>
                  <p className={styles.p}>
                    Bolitas empanizadas rellenas de queso y tocino, acompañadas
                    de aderezo mayo chipotle.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <div className={styles.embla__slide__image}>
                    <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
                      height="230"
                      width="230"
                      alt="Sesame - Láminas de pollo, brócoli, ejote, pimiento, zanahoria,
                      cebolla, ajonjoí, salsa dulce oriental."
                    />
                    <Button className={styles.button__float}>
                      <a
                        href="https://pide.rockandwok.com/rocknwok/new"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        ordenar en línea
                      </a>
                    </Button>
                  </div>

                  <h5 className={styles.h5}>Sesame</h5>
                  <p className={styles.p}>
                    Láminas de pollo, brócoli, ejote, pimiento, zanahoria,
                    cebolla, ajonjoí, salsa dulce oriental.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <div className={styles.embla__slide__image}>
                    <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
                      height="230"
                      width="150"
                      alt="Té de jazmín - Nuestra deliciosa agua de la casa elaborada con infusión de
                      jazmín, un toque de limón y endulzante. "
                    />
                    <Button className={styles.button__float}>
                      <a
                        href="https://pide.rockandwok.com/rocknwok/new"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        ordenar en línea
                      </a>
                    </Button>
                  </div>

                  <h5 className={styles.h5}>Té de jazmín</h5>
                  <p className={styles.p}>
                    Nuestra deliciosa agua de la casa elaborada con infusión de
                    jazmín, un toque de limón y endulzante.
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <div className={styles.embla__slide__image}>
                    <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
                      width="222"
                      height="222"
                      alt="ZZ Wok - Pollo crispy, pimiento, apio, cebolla, ejote, salsa de
                      chiles."
                    />
                    <Button className={styles.button__float}>
                      <a
                        href="https://pide.rockandwok.com/rocknwok/new"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        ordenar en línea
                      </a>
                    </Button>
                  </div>

                  <h5 className={styles.h5}>ZZ Wok</h5>
                  <p className={styles.p}>
                    Pollo crispy, pimiento, apio, cebolla, ejote, salsa de
                    chiles.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <div className={styles.embla__slide__image}>
                    <img
              src={rwImg5}
              srcset={rwImg5x2 + ' 2x'}
                      height="230"
                      width="230"
                      alt="Orange - Pollo crispy en nuestra salsa de naranaja especial."
                    />
                    <Button className={styles.button__float}>
                      <a
                        href="https://pide.rockandwok.com/rocknwok/new"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        ordenar en línea
                      </a>
                    </Button>
                  </div>

                  <h5 className={styles.h5}>Orange</h5>
                  <p className={styles.p}>
                    Pollo crispy en nuestra salsa de naranaja especial.
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <div className={styles.embla__slide__image}>
                    <img
              src={rwImg6}
              srcset={rwImg6x2 + ' 2x'}
                      height="230"
                      width="230"
                      alt="Beef n’ broccoli - Láminas de res, brócoli, cebollín, jengíbre, salsa estilo
                      cantonés."
                    />
                    <Button className={styles.button__float}>
                      <a
                        href="https://pide.rockandwok.com/rocknwok/new"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        ordenar en línea
                      </a>
                    </Button>
                  </div>

                  <h5 className={styles.h5}>Beef n’ broccoli</h5>
                  <p className={styles.p}>
                    Láminas de res, brócoli, cebollín, jengíbre, salsa estilo
                    cantonés.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.arrows}>
          <div
            className={styles.arrow}
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
          >
            <img
              src="/Flecha_Slider_Atras.svg"
              height="19"
              width="40"
              alt="Slider Flecha Atrás"
            />
          </div>
          <div className={styles.dots}>
            {scrollSnaps.map((_, index) => {
              if (index === selectedIndex) {
                return (
                  <div className={styles.circle}>
                    <img
                      key={index}
                      onClick={() => scrollTo(index)}
                      src="/SliderBotonesNuevos.svg"
                      alt="felin"
                    />
                  </div>
                );
              } else {
                return (
                  <img
                    className={styles.circle2}
                    key={index}
                    onClick={() => scrollTo(index)}
                    src="/SliderBotonesNuevos.svg"
                    alt="felin"
                  />
                );
              }
            })}
          </div>
          <div
            className={styles.arrow}
            onClick={scrollNext}
            enabled={nextBtnEnabled}
          >
            <img
              src="/Flecha_Slider_Siguiente.svg"
              height="19"
              width="40"
              alt="Flecha Slider Siguiente"
            />
          </div>
        </div>
      </div>
      <Link to="/menu" className={styles.link}>
        <Button className={styles.button__alone}>ver menú completo</Button>
      </Link>
    </div>
  );
};

export default Slider;
