import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Aos from "aos";
import "aos/dist/aos.css";


import Ringredientes from '../../assets/static/Contacto_RockAndRoll.png';
import Ringredientes2x from '../../assets/static/Contacto_RockAndRoll@2x.png';

const Contact = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.contacto}>
      <div className={styles.container}>
        <div>
          <Title dos="fade-left" className={styles.title__white}>¿quieres</Title>
          <Title dos="fade-right" className={styles.title}>decirnos</Title>
          <Title dos="fade-left" className={styles.title__white}>algo?</Title>
          <Title dos="fade-right" className={styles.title__white2}>
            Estamos para <br />
            escucharte
          </Title>
          <p data-aos="fade-up" className={styles.subtitle}>Mandanos un e-mail</p>
          <p data-aos="fade-up" className={styles.text}>¿Dudas, quejas o sugerencias?</p>
          <p data-aos="fade-up" className={styles.text}>Envíanos un correo a:</p>
          <p data-aos="fade-up" className={styles.text__warning_}>
            direccionoperaciones@rockandwok.com
          </p>
          <hr data-aos="fade-up" className={styles.hr}/>
          <p data-aos="fade-up" className={styles.subtitle}>Rock with us</p>
          <p data-aos="fade-up" className={styles.text}>
            ¿Estás interesado en trabajar con nosotros?
          </p>
          <p data-aos="fade-up" className={styles.text}>Envíanos un correo a:</p>
          <p data-aos="fade-up" className={styles.text__warning}>capitalhumano@rockandwok.com </p>
        </div>
        <div data-aos="fade-left" className={styles.images}>
          <div className={styles.image}>
            <img
              src={Ringredientes}
              srcset={Ringredientes2x + ' 2x'}
              height="560"
              width="560"
              alt="Wanna Wok"
              className="wobble-hor-bottom "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
