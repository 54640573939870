import React, { useState, useEffect } from "react";
import styles from "./Header.module.css";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Button";

const Header = () => {
  const [togleBar, setTogle] = useState(false);
  const router = useLocation();
  const [pos, setPos] = useState("top");
  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      // let scrolled = document.scrollingElement.scrollTop;
      setPos(window.scrollY);
      // setPos(scrolled);
    });
  }, []);

  return (
    <>
      <header
        className={`${
          router.pathname !== "/menu" && router.pathname !== "/privacystatement"
            ? styles.header
            : styles.header__white
        } ${pos > 800 ? styles.fixed : ""} ${
          pos > 600 && pos < 900 ? styles.opacity : styles.opacity2
        }`}
      >
        <div className={styles.header__container}>
          <Link to="/">
            <img
              src={
                router.pathname !== "/menu" &&
                router.pathname !== "/privacystatement"
                  ? "/RockNWok_Logo_Negativo.svg"
                  : "/RockNWok_Logo_Positivo.svg"
              }
              height="53"
              width="218"
              alt="Logotipo Rock and Wok"
            />
          </Link>
          <div
            className={styles.header__menuMobile}
            onClick={() => setTogle(!togleBar)}
          >
            <img
              src={
                router.pathname !== "/menu" &&
                router.pathname !== "/privacystatement"
                  ? "/Boton_Menu.svg"
                  : "/Boton_Menu_Positivo.svg"
              }
              alt="Boton Menú"
            />
          </div>
          <nav
            className={
              togleBar ? styles.header__nav__active : styles.header__nav
            }
          >
            <div onClick={() => setTogle(false)}>
              <img
                src="/Menu_Cerrar.svg"
                className={styles.iconClose}
                alt="Botón Menú Cerrar"
              />
            </div>
            <ul className={styles.header__menu}>
              <li
                className={
                  router.pathname !== "/menu" &&
                  router.pathname !== "/privacystatement"
                    ? styles.header__item
                    : styles.header__item__white
                }
              >
                <Link onClick={() => setTogle(!togleBar)} to="/">
                  {" "}
                  nosotros{" "}
                </Link>
              </li>
              <li
                className={
                  router.pathname !== "/menu" &&
                  router.pathname !== "/privacystatement"
                    ? styles.header__item
                    : styles.header__item__white
                }
              >
                <Link onClick={() => setTogle(!togleBar)} to="/sucursales">
                  {" "}
                  sucursales{" "}
                </Link>
              </li>
              <li
                className={
                  router.pathname !== "/menu" &&
                  router.pathname !== "/privacystatement"
                    ? styles.header__item
                    : styles.header__item__white
                }
              >
                <Link onClick={() => setTogle(!togleBar)} to="/contacto">
                  {" "}
                  contacto{" "}
                </Link>
              </li>
              <div className={styles.header__buttons__mobile}>
                <Button className={styles.button__warning}>
                  <Link onClick={() => setTogle(!togleBar)} to="/menu">
                    Menú
                  </Link>
                </Button>
                <Button className={styles.button}>Ordena en línea</Button>
              </div>
            </ul>
            <div className={styles.header__icon_menuMobile}>
              <img
                src="/RockNWok_Logo_Negativo.svg"
                height="50"
                width="150"
                alt="Rock and Wok Logo Negativo"
                className={styles.imagebottom}
              />
              <div className={styles.iconSocial}>
                <img src="/Footer_IconsSocial_Facebook.svg" alt="Logo Facebook" />

                <img src="/Footer_IconsSocial_Instagram.svg" alt="Logo Instagram" />
              </div>
            </div>
          </nav> 
          <div className={styles.header__buttons}>
            <Button className={styles.button__warning}>
              <Link onClick={() => setTogle(!togleBar)} to="/menu">
                Menú
              </Link>
            </Button>
            <Button className={styles.button}>
              <a href="https://pide.rockandwok.com/rocknwok/new" target="_blank">Ordena en línea</a>
            </Button>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
