import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import Layout from "../layout";
import Home from "../pages/index";
import Menu from "../pages/menu";
import Sonata from "../pages/sonata";
import Branches from "../pages/branches";
import Contact from "../pages/contact";
import Privacy from "../pages/privacystatement";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Layout>
          <Switch> 
            <Route exact path="/" component={Home} />
            <Route exact path="/menu" component={Menu} />
            <Route exact path="/sucursales" component={Branches} />
            <Route exact path="/contacto" component={Contact} />
            <Route exact path="/sonata" component={Sonata} />
            <Route exact path="/aviso-de-privacidad" component={Privacy} />
          </Switch>
        </Layout>
      </ScrollToTop>
    </BrowserRouter>
  );
  
};

export default App;
