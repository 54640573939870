import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Button from "../../components/Button";
import { HashLink } from "react-router-hash-link";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/WokHome_01.png';
import rwImg1x2 from '../../assets/static/WokHome_01@2x.png';

import rwImg2 from '../../assets/static/WokHome_02.png';
import rwImg2x2 from '../../assets/static/WokHome_02@2x.png';

import rwImg3 from '../../assets/static/WokPrincipal.png';
import rwImg3x2 from '../../assets/static/WokPrincipal@2x.png';

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.container} data-aos="fade-down">
        <Title>ROCKIN’</Title>
        <Title className={styles.title__white}>spots</Title>
      </div>
      <Button className={styles.button}>
        <HashLink smooth to="/sucursales#sucursales">
          conoce nuestras sucursales
        </HashLink>
      </Button>
      <div className={styles.bg__woks} data-aos="fade-up">
        <div className={styles.woks}>
          <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
            height="600"
            width="630"
            alt="Wok Principal"
          />
        </div>
        <div className={styles.woks2}>
          <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
            height="600"
            width="630"
            alt="Wok Principal"
          />
        </div>
        <div className={styles.woks3}>
          <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
            height="780"
            width="630"
            alt="Wok Principal"
            
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
