import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/ArmaTuBox_01.1_LaBase_ArrozAlVapor.png';
import rwImg1x2 from '../../assets/static/ArmaTuBox_01.1_LaBase_ArrozAlVapor@2x.png';

import rwImg2 from '../../assets/static/ArmaTuBox_01.2_LaBase_ArrozFrito.png';
import rwImg2x2 from '../../assets/static/ArmaTuBox_01.2_LaBase_ArrozFrito@2x.png';

import rwImg3 from '../../assets/static/ArmaTuBox_01.3_LaBase_ArrozSpicy.png';
import rwImg3x2 from '../../assets/static/ArmaTuBox_01.3_LaBase_ArrozSpicy@2x.png';

import rwImg4 from '../../assets/static/ArmaTuBox_01.4_LaBase_Noodles.png';
import rwImg4x2 from '../../assets/static/ArmaTuBox_01.4_LaBase_Noodles@2x.png';

const Base = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.container}>
      <Title dos="fade-up" className={styles.title}>
        la
      </Title>
      <Title dos="fade-up" className={styles.title__}>
        base
      </Title>
      <Subtitle dos="fade-up">
        Lo primero es la base, ¡selecciona <br /> tu base favorita!
      </Subtitle>
      <div className={styles.grid}>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
              width="150"
              height="150"
              alt="Base - Arroz al vapor - Arroz cocido sin ningún condimento."
            />
            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Arroz al vapor
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz cocido sin ningún condimento.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={`${styles.embla__slide__image} ${styles.embla__slide__image__}`} data-aos="fade-up">
            <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
              width="150"
              height="150"
              alt="Base - Arroz frito - Arroz frito, zanahoria, germen de soya, huevo, salsa de soya."
            />
            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Arroz frito
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz frito, zanahoria, germen de soya, huevo, salsa de soya.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
              width="150"
              height="162"
              alt="Base - Arroz Spicy - Arroz picante, zanahoria, germen de soya, elote, champiñón, huevo, salsa de soya."
            />
            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Arroz spicy
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz picante, zanahoria, germen de soya, elote, champiñón, huevo,
            salsa de soya.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
              width="150"
              height="150"
              alt="Base - Noodles - Fideos, zanahoria, apio, tallo de brócoli, salsa de soya."
            />
            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Noodles
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Fideos, zanahoria, apio, tallo de brócoli, salsa de soya.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Base;
