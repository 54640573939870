import React from "react";
import Header from "../containers/Header";
import Footer from "../containers/Footer";
import styles from "./Layout.module.css";
import "../assets/styles/normalize.css";
import "../assets/styles/globals.css";
import "../assets/styles/animations.css";

const Layout = ({ children }) => (
  <div className={styles.Layout}>
    <Header />
    <>{children}</>
    <Footer />
  </div>
);

export default Layout;
