import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import { BsDash } from "react-icons/bs";
import Aos from "aos";
import "aos/dist/aos.css";


import rwImg1 from '../../assets/static/RockNWok_Cajitas.jpg';
import rwImg1x2 from '../../assets/static/RockNWok_Cajitas@2x.jpg';

const Lets = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className={styles.lets}>
      <div className={styles.container}>
        <div className={styles.text}>
          <Title dos="fade-right" className={styles.title__dark}>
            LET’S
          </Title>
          <Title dos="fade-right" className={styles.title__dark}>
            PUT
          </Title>
          <Title dos="fade-right" className={styles.title}>
            THINGS
          </Title>
          <Title dos="fade-right" className={styles.title}>
            STRAIGHT
          </Title>
          <h3 data-aos="fade-right" className={styles.subtitle}>
            En Rock n’ Wok rockeamos cuando:
          </h3>
          <ul data-aos="fade-right" className={styles.ul}>
            <li>
              {" "}
              <BsDash /> Sorprendemos
            </li>
            <li>
              {" "}
              <BsDash /> Somos creativos.
            </li>
            <li>
              {" "}
              <BsDash /> Nos atrevemos.
            </li>
            <li>
              {" "}
              <BsDash /> Hacemos las cosas con pasión.
            </li>
            <li>
              {" "}
              <BsDash /> Damos más de lo que se espera de nosotros.
            </li>
            <li>
              {" "}
              <BsDash /> Cuídamos al planeta y somos empáticos.
            </li>
            <li>
              {" "}
              <BsDash /> Hacemos las cosas a nuestra manera.
            </li>
            <li>
              {" "}
              <BsDash /> Somos auténticos.
            </li>
            <li>
              {" "}
              <BsDash /> Jugamos con fuego.
            </li>
            <li>
              {" "}
              <BsDash /> Vivimos el presente.
            </li>
          </ul>
          <h3 data-aos="fade-right" className={styles.subtitle2}>
            Do you rock?
          </h3>
        </div>
        <div className={styles.images}>
          <div className={styles.image}>
            <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
              height="700"
              width="700"
              alt="Rock and wok cajitas"
              data-aos="fade-left"
            />
          </div>
          <div className={styles.image__float}>
            <img
              src="/BadgeWokVerde.svg"
              height="135"
              width="81"
              alt="Icono Verde"
              data-aos="fade-left"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Lets;
