import Branches1 from "../containers/Branches";
import Sucursales from "../containers/Sucursales";
import {Helmet} from "react-helmet";

export default function Home() {
  return (
    <main>
      <Helmet>
        <title>ROCK N' WOK - Sucursales</title>
        <meta name="description" content="Rockin' Spots - Conoce nuestras sucursales dentro de la república mexicana." />
      </Helmet>
      <Branches1 />
      <Sucursales title="sucursales" />
    </main>
  );
}
