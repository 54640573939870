import Menu1 from "../containers/Menu";
import Base from "../containers/Base";
import LaEspecialidad from "../containers/LaEspecialidad";
import Arroces from "../containers/Arroces";
import FullBox from "../containers/FullBox";
import Especialidades from "../containers/Especialidades";
import NuestrosAcom from "../containers/NuestrosAcom";
import RockinSweet from "../containers/RockinSweet";
import {Helmet} from "react-helmet";

export default function Menu() {
  return (
    <main>
      <Helmet>
        <title>ROCK N' WOK - Menú</title>
        <meta name="description" content="Arma tu box con tu base favorita + alguna de nuestras deliciosas especialidades." />
      </Helmet>
      <Menu1 />
      <Base />
      <LaEspecialidad />
      <Arroces 
        title="Arroces" 
        subtitle={<>Nuestros deliciosos arroces fritos con <br /> toppings. Rice up!</>}
        size="350"
        QR="true"  
       />
      <FullBox />
      <Especialidades />
      <NuestrosAcom />
      <RockinSweet />
    </main>
  );
}
/* Mapa por filtros */
