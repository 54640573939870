import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.box}>
        <img
          src="/RockNWok_LogoFooter.svg"
          height="111"
          width="131"
          alt="Logo Rock and Wok - ROCK N' WOK"
        />
        <a href="https://undi.mx/" className={styles.undi_link} target='_blank'>
          Parte de Undi Logística S.A. de C.V.
        </a>
      </div>
      <div className={styles.info}>
        <h3 className={styles.title}>ROCKEA CON</h3>
        <h3 className={styles.title__warning}>NOSOTROS</h3>
        <div className={styles.iconSocial}>
          <a href="https://www.facebook.com/rockandwok" target="_blank">
            <img
              src="/Footer_IconsSocial_Facebook.svg"
              height="19"
              width="19"
              alt="Logo Facebook"
            />
          </a>
        </div>
        <div className={styles.iconSocial}>
          <a href="https://www.instagram.com/rockandwok_mx/" target="_blank">
            <img
              src="/Footer_IconsSocial_Instagram.svg"
              height="19"
              width="19"
              alt="Logo Instagram"
            />
          </a>
        </div>
        <p className={styles.p_under}>
          <Link to="/aviso-de-privacidad">Aviso de Privacidad.</Link>
        </p>
        <p className={styles.p}>
          Copyright 2021. Rock n´wok. <br />
          Todos los derechos reservados.
        </p>
      </div>
      <div className={styles.icon}>
        <img src="/Footer_Box.svg" height="200" width="110" alt="Footer Box Rock and Wok" />
      </div>
    </footer>
  );
};

export default Footer;
