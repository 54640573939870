import React from "react";
import styles from "./styles.module.css";

const Loading = ({ show }) => {
  return (
    <div
      className={`${styles.trasition} ${
        show === true ? styles.container : styles.container__hide
      }`}
    ></div>
  );
};

export default Loading;
