import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/Arroces_01_Poblanito.png';
import rwImg1x2 from '../../assets/static/Arroces_01_Poblanito@2x.png';

import rwImg2 from '../../assets/static/Arroces_02_Keshito.png';
import rwImg2x2 from '../../assets/static/Arroces_02_Keshito@2x.png';

import rwImg3 from '../../assets/static/Arroces_03_Dragon.png';
import rwImg3x2 from '../../assets/static/Arroces_03_Dragon@2x.png';

import rwImg4 from '../../assets/static/Arroces_04_Tiger.png';
import rwImg4x2 from '../../assets/static/Arroces_04_Tiger@2x.png';

const Base = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.float}>
        <img
          src="/BadgeArroces.svg"
          width="100"
          height="167"
          
          alt="Icono Arroces"
        />
      </div>
      <Title dos="fade-up" className={styles.title__}>
        arroces
      </Title>
      <Subtitle dos="fade-up" className={styles.subtitle}>
        Nuestros deliciosos arroces fritos con <br /> toppings. Rice up!
      </Subtitle>
      <div className={styles.grid}>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
              width="350"
              height="350"
              
              alt="Arroz el poblanito - Arroz frito, milanesa de pollo, quesillo, guacamole, aderezo jalapeño."
            />
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Poblanito
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz frito, milanesa de pollo, quesillo, guacamole, aderezo
            jalapeño.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
              width="350"
              height="350"
              alt="Arroz Keshito - Arroz frito, 5 mini rockshiages, tampico, aderezo mayo chipotle."
            />
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Keshito
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz frito, 5 mini rockshiages, tampico, aderezo mayo chipotle.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
              width="350"
              height="350"
              
              alt="Arroz Dragon - Arroz spicy, pollo asado, aguacate, queso crema, papitas de queso."
            />
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Dragon
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz spicy, pollo asado, aguacate, queso crema, papitas de queso.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
              width="350"
              height="350"
              
              alt="Arroz Tiger - Arroz frito, nuestro delicioso pollo zz, huevo revuelto, plátano frito."
            />
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Tiger
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Arroz frito, nuestro delicioso pollo zz, huevo revuelto, plátano
            frito.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Base;
