// import React, { useState, useEffect } from "react";
import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/WokHome_01.png';
import rwImg1x2 from '../../assets/static/WokHome_01@2x.png';

import rwImg2 from '../../assets/static/WokHome_02.png';
import rwImg2x2 from '../../assets/static/WokHome_02@2x.png';

import rwImg3 from '../../assets/static/WokPrincipal.png';
import rwImg3x2 from '../../assets/static/WokPrincipal@2x.png';

import rwImg4 from '../../assets/static/WokPrincipal_Vapor.png';
import rwImg4x2 from '../../assets/static/WokPrincipal_Vapor@2x.png';

const Home = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    Aos.init({ duration: 2000 });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={styles.home}>
      <div className={styles.container}>
        <Title dos="fade-left">let&apos;s</Title>
        <Title dos="fade-right">rock n&apos;</Title>
        <Title dos="fade-left" className={styles.title__white}>
          wok
        </Title>
      </div>
      <Button className={styles.button} dos="fade-down">
        <a href="https://pide.rockandwok.com/rocknwok/new" target="_blank">
          ordena en linea
        </a>
      </Button>
      <div className={styles.bg__woks} data-aos="fade-up">
        <div className={styles.woks}>
          <img
            src={rwImg1}
            srcset={rwImg1x2 + ' 2x'}
            height="600"
            width="630"
            alt="Wok Principal"
          />
          <img
            src="/WokFlames_01.svg"
            height="600"
            width="630"
            alt="Llamas"
            className={styles.particle1}
          />
        </div>
        <div className={styles.woks2}>
          <img
            src={rwImg2}
            srcset={rwImg2x2 + ' 2x'}
            height="600"
            width="630"
            alt="Wok Principal"
          />
          <img
            src="/WokFlames_01.svg"
            height="600"
            width="630"
            alt="Llamas"
            className={styles.particle2}
          />
        </div>
        <div className={styles.woks3}>
          <img
            src={rwImg3}
            srcset={rwImg3x2 + ' 2x'}
            height="780"
            width="630"
            alt="Wok Principal"
          />
          <img
            src={rwImg4}
            srcset={rwImg4x2 + ' 2x'}
            height="780"
            width="630"
            alt="Vapor"
            className={`${styles.cloud} fade-in-top`}
          />
        </div>
      </div>
      <div className={styles.ok}>
        <img
          src="/RockNWokBadge_OKOK.svg"
          height="170"
          width="103"
          alt="Ok ok logo"
          style={{ transform: `translateY(-${offsetY * 0.2}px)` }}
        />
      </div>
    </div>
  );
};

export default Home;
