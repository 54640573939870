import React, { useEffect } from "react";

import styles from "./styles.module.css";
import Title from "../../components/Title";
import Aos from "aos";
import "aos/dist/aos.css";

import Ringredientes from '../../assets/static/RockNWok_Ingredientes.png';
import Ringredientes2x from '../../assets/static/RockNWok_Ingredientes@2x.png';

const Cocinamos = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.cocinamos}>
      <div className={styles.image}>
        <img
          src={Ringredientes}
          srcset={Ringredientes2x + ' 2x'}
          height="802"
          width="518"
          alt="Tabla de Ingredientes"
          data-aos="fade"
        />
      </div>
      <div className={styles.float}>
        <img
          src="/BadgeVerde_RockNWok.svg"
          height="167"
          width="100"
          alt="Icono Verde Rock and Wok"
          data-aos="fade"
        />
      </div>
      <div className={styles.text}>
        <Title dos="fade-up" className={styles.title1}>
          Cocinamos
        </Title>
        <Title dos="fade-left" className={styles.title}>
          al momento
        </Title>
        <Title dos="fade-right" className={styles.title__}>
          con insumos
        </Title>
        <Title dos="fade-down" className={styles.title__}>
          frescos
        </Title>
      </div>
    </div>
  );
};

export default Cocinamos;
