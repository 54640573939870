import React, { useState } from "react";
import GoogleMap from "google-map-react";
import pin from "../../../assets/static/PinUbicacion_Puebla.svg";
import pin2 from "../../../assets/static/PinUbicacion_Queretaro.svg";
import styles from "./styles.module.css";

const Map = (props) => {
  const { lat, lng, zoom } = props;

  const [brands, setBrand] = useState([
    {
      name: "Explanada",
      lat: "19.070834144674134",
      lng: " -98.2773072880333",
      pin: "yellow",
      show: false,
    },

    {
      name: "SONATA",
      pin: "yellow",
      show: false,
      lat: "18.99385475942184",
      lng: "-98.27893764944838",
    },
    {
      name: "UPAEP",
      lat: "19.047578457751484",
      lng: " -98.21886724944781",
      pin: "yellow",
      show: false,
    },
    {
      name: "Vía san ángel",
      lat: "19.0271528",
      lng: "-98.2729008",
      pin: "yellow",
      show: false,
    },
    {
      name: "PASEO QUERÉTARO",
      lat: "20.631690021457743",
      lng: "-100.35781693880061",
      pin: "red",
      show: false,
    },
  ]);

  const center = [parseFloat(lat), parseFloat(lng)];

  const _onChildClick = (index) => {
    const newBrands = brands.map((brand, i) => {
      if (i === index) {
        return {
          ...brand,
          show: !brand.show,
        };
      } else {
        return {
          ...brand,
          show: false,
        };
      }
    });
    setBrand(newBrands);
  };

  const Marker = (props) => {
    return (
      <>
        <img
          src={props.pin !== "red" ? pin : pin2}
          alt=""
          className={styles.pin}
        />
        {/* Below is info window component */}
        {props.show && <div className={styles.infoMap}>{props.name}</div>}
      </>
    );
  };

  return (
    // Important! Always set the container height explicitly
    <div className={styles.map}>
      <GoogleMap
        bootstrapURLKeys={{ key: "AIzaSyAeOLaJsbTpcoK-OLc7ORwk0x7-ooZwN8A" }}
        center={center}
        zoom={zoom}
        onChildClick={(e) => _onChildClick(e)}
      >
        {brands.map((brand, i) => (
          <Marker
            algo={i}
            lat={brand.lat}
            lng={brand.lng}
            pin={brand.pin}
            name={brand.name}
            show={brand.show}
          />
        ))}
      </GoogleMap>
    </div>
  );
};

export default Map;
