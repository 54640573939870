import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/WokMenu_ArmaTuBox_01.png';
import rwImg1x2 from '../../assets/static/WokMenu_ArmaTuBox_01@2x.png';

import rwImg2 from '../../assets/static/WokMenu_ArmaTuBox_02.png';
import rwImg2x2 from '../../assets/static/WokMenu_ArmaTuBox_02@2x.png';

const Home = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.home}>
      <div className={styles.container}>
        <Title dos="fade-down" className={styles.title}>
          arma
        </Title>
        <Title dos="fade-down">tu box</Title>
        <Subtitle dos="fade-up">
          Haz tu combinación: Selecciona una base <br />y acompáñala con tu
          especialidad favorita.
        </Subtitle>
      </div>
      <div className={styles.bg__woks}>
        <div className={styles.woks}>
          <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
            height="600"
            width="630"
            alt="Wok Menu Arma tu box"
          />
          <img
            src="/WokFlames_01.svg"
            height="600"
            width="630"
            alt="Flamas"
            className={styles.particle1}
          />
        </div>
        <div className={styles.woks2}>
          <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
            height="600"
            width="630"
            alt="Wok Menu Arma tu box"
          />
          <img
            src="/WokFlames_01.svg"
            height="600"
            width="630"
            alt="Flama"
            className={styles.particle2}
          />
        </div>
        <div className={styles.central}>
          <div className={styles.img1} data-aos="fade" data-aos-delay="500">
            <img
              src="/ArmaTuBox_Diagrama_01.svg"
              height="158"
              width="273"
              alt="Wok Menu Arma tu box diagrama"
            />
          </div>
          <div className={styles.img2}>
            <img
              src="/ArmaTuBox_Diagrama_04_Separacion.svg"
              data-aos="new-animation"
              data-aos-delay="1000"
              height="251.25"
              width="11.25"
              alt="Separación"
            />
          </div>
          <div className={styles.img3}>
            <img
              src="/ArmaTuBox_Diagrama_02_Base.svg"
              height="168"
              width="181"
              alt="Wok Menu Arma tu box base"
              data-aos="fade-right"
              data-aos-delay="1500"
            />
          </div>
          <div className={styles.img4}>
            <img
              src="/ArmaTuBox_Diagrama_03_Especialidad.svg"
              height="204"
              width="261"
              alt="Wok Menu Arma tu box especialidad"
              data-aos="fade-left"
              data-aos-delay="1500"
            />
          </div>
        </div>
      </div>
      <div className={styles.ok}>
        <img
          src="/RockNWokBadge_OKOK.svg"
          height="170"
          width="103"
          alt="Logo Ok Ok"
        />
      </div>
    </div>
  );
};

export default Home;
