import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/RockNWok_Imagenes01.png';
import rwImg1x2 from '../../assets/static/RockNWok_Imagenes01@2x.png';

import rwImg2 from '../../assets/static/RockNWok_Imagenes02.png';
import rwImg2x2 from '../../assets/static/RockNWok_Imagenes02@2x.png';

import rwImg3 from '../../assets/static/RockNWok_Imagenes04.png';
import rwImg3x2 from '../../assets/static/RockNWok_Imagenes04@2x.png';

import rwImg4 from '../../assets/static/RockNWok_Imagenes03.png';
import rwImg4x2 from '../../assets/static/RockNWok_Imagenes03@2x.png';

import rwImg5 from '../../assets/static/RockNWok_Imagenes05.png';
import rwImg5x2 from '../../assets/static/RockNWok_Imagenes05@2x.png';

import rwImg6 from '../../assets/static/RockNWok_Imagenes06.png';
import rwImg6x2 from '../../assets/static/RockNWok_Imagenes06@2x.png';

import rwImg7 from '../../assets/static/RockNWok_Imagenes07.png';
import rwImg7x2 from '../../assets/static/RockNWok_Imagenes07@2x.png';

import rwImg8 from '../../assets/static/RockNWok_Imagenes08.png';
import rwImg8x2 from '../../assets/static/RockNWok_Imagenes08@2x.png';

const RockWithUs = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.rockwithus}>
      <div className={styles.text}>
        <Title dos="fade-left" className={styles.title}>
          do you
        </Title>
        <Title dos="fade-rigth" className={styles.title__}>
          rock?
        </Title>
      </div>
      <div className={styles.box1} data-aos="fade-right">
        <div className={styles.img1}>
          <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
            height="336"
            width="539"
            alt="Imágenes Rock and Wok"
          />
        </div>
      </div>
      <div className={styles.box2}>
        <div className={styles.img2} data-aos="fade-right">
          <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
            height="225"
            width="334"
            alt="Imágenes Rock and Wok"
          />
        </div>
        <div className={styles.img3} data-aos="fade-up">
          <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
            height="497"
            width="862"
            alt="Imágenes Rock and Wok"
          />
        </div>
        <div className={styles.img4} data-aos="fade-left">
          <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
            height="247"
            width="439"
            alt="Imágenes Rock and Wok"
          />
        </div>
        <div className={styles.float} data-aos="fade-down">
          <img
            src="/StickerCirculoWokNegro.svg"
            height="127"
            width="127"
            alt="Sticker Círculo Wok Negro"
          />
        </div>
      </div>
      <div className={styles.text2}>
        <Title dos="fade-left" className={styles.title}>
          come rock
        </Title>
        <Title dos="fade-right" className={styles.title__}>
          with us!
        </Title>
      </div>
      <div className={styles.box3}>
        <div className={styles.img5} data-aos="fade-right">
          <img
              src={rwImg5}
              srcset={rwImg5x2 + ' 2x'}
            height="351"
            width="531"
            alt="Imágenes Rock and Wok"
          />
        </div>
        <div
          className={styles.img6}
          data-aos="fade-left"
          style={{ animation: `spin 10s linear infinite !important;` }}
        >
          <img
              src={rwImg6}
              srcset={rwImg6x2 + ' 2x'}
            height="489"
            width="675"
            alt="Imágenes Rock and Wok"
            style={{ animation: `spin 10s linear infinite !important;` }}
          />
        </div>
        <div className={styles.float2}>
          <img
            className="spinner"
            src="/ComeRockWithUs.svg"
            height="115"
            width="115"
            alt="Come Rock with us"
          />
        </div>
      </div>
      <div className={styles.box4}>
        <div className={styles.img7} data-aos="fade-right">
          <img
              src={rwImg7}
              srcset={rwImg7x2 + ' 2x'}
            height="376"
            width="600"
            alt="Imágenes Rock and Wok"
          />
        </div>
        <div className={styles.img8} data-aos="fade-left">
          <img
              src={rwImg8}
              srcset={rwImg8x2 + ' 2x'}
            height="383"
            width="600"
            alt="Imágenes Rock and Wok"
          />
        </div>
        <div className={styles.float3} data-aos="fade-up">
          <img
            src="/OkCajita.svg"
            height="168"
            width="113"
            alt="Ok Cajita"
          />
        </div>
      </div>
     
      </div>
  );
};

export default RockWithUs;
