import React from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import {Helmet} from "react-helmet";

const Privacy = () => {
  return (
    <div className={styles.privacy}> 
    <Helmet>
        <title>ROCK N' WOK- Aviso de Privacidad</title>
        <meta name="description" content="En UNEF operaciones S. A. de C. V., con Domicilio en Vía Atlixcáyotl 1504. Loca FF3. Col. Reserva Territorial Atlixcáyotl, Puebla. C. P. 72810, la información de nuestros clientes y clientes potenciales es tratada de forma estrictamente confidencial y es muy imprtante por lo que hacemos un esfuerzo permanente para salvaguardarla." />
      </Helmet>
      <Title className={styles.title__dark}>AVISO DE</Title>
      <Title className={styles.title}>PRIVACIDAD</Title>
      <p className={styles.text}>
        <strong>ROCK N' WOK</strong><br></br>
        UNEF Operaciones S.A. de C.V.
      </p>
      <p className={styles.text}>
      Correo de Contacto: contacto@rockandwok.com
      </p>
      <p className={styles.text}>
      En cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) y su Reglamento, UNEF Operaciones S.A. de C.V., con nombre comercial Rock N' Wok, establece el siguiente Aviso de Privacidad:
      </p>
      <p className={styles.text}><strong></strong>
      <strong>1. Información Recabada</strong>
      </p>
      <p className={styles.text}>
        Rock N' Wok recopila información personal de sus clientes y usuarios con el propósito de proporcionar productos y servicios de calidad. La información recabada puede incluir, pero no se limita a:
      </p>
      <p className={styles.text}>
      - Nombre y apellidos<br></br>
- Dirección de correo electrónico<br></br>
- Número de teléfono<br></br>
- Información de facturación<br></br>
- Información de tarjeta de crédito/débito (en caso de transacciones)
      </p>
      <p className={styles.text}>
      <strong>2. Uso de la Información</strong>
      </p>
      <p className={styles.text}>
      La información recopilada será utilizada para:
        </p>
        <p className={styles.text}>
        - Procesar pedidos y transacciones<br></br>
- Personalizar la experiencia del usuario<br></br>
- Enviar actualizaciones sobre productos y promociones<br></br>
- Mejorar nuestros productos y servicios
        </p>
        <p className={styles.text}>
        <strong>3. Protección de Datos</strong>
        </p>
        <p className={styles.text}>
        Rock N' Wok implementa medidas de seguridad adecuadas para proteger la información personal contra pérdida, uso no autorizado, acceso indebido, divulgación, alteración y destrucción.
        </p>
        <p className={styles.text}>
        <strong>4. Compartir Información</strong>
        </p>
        <p className={styles.text}>
        La información personal no será compartida con terceros, salvo consentimiento expreso del titular o en los casos establecidos por la ley.
        </p>
        <p className={styles.text}>
        <strong>5. Derechos ARCO</strong>
        </p>
        <p className={styles.text}>
        Los titulares de los datos personales tienen derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO) respecto a sus datos. Estos derechos pueden ejercerse a través del correo electrónico contacto@rockandwok.com.
        </p>
        <p className={styles.text}>
        <strong>6. Cookies y Tecnologías Similares</strong>
        </p>
        <p className={styles.text}>
        Rock N' Wok utiliza cookies y tecnologías similares para mejorar la experiencia del usuario. Al navegar en nuestro sitio web, se acepta el uso de estas tecnologías.
        </p>
        <p className={styles.text}>
        <strong>7. Cambios al Aviso de Privacidad</strong>
        </p>
        <p className={styles.text}>
        Rock N' Wok se reserva el derecho de realizar cambios a este Aviso de Privacidad. Se notificarán cambios significativos a través de nuestro sitio web o por correo electrónico.
        </p>
        <p className={styles.text}>
        <strong>Contacto</strong>
        </p>
        <p className={styles.text}>
        Para cualquier duda o comentario sobre este Aviso de Privacidad, por favor, póngase en contacto con nosotros a través de la dirección de correo electrónico contacto@rockandwok.com.
        </p>
        <p className={styles.text}>
        Fecha de última actualización: 2 de enero de 2023
        </p>
        <p className={styles.text}>
        Agradecemos su confianza y preferencia.
        </p>
        <p className={styles.text}>
        ROCK N' WOK - UNEF Operaciones S.A. de C.V.
        </p>

        
    </div>
  );
};

export default Privacy;
