import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { BsCircle } from "react-icons/bs";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";
import "aos/dist/aos.css";

const Base = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.container}>
      <Title dos="fade-up" className={styles.title}>
        personaliza
      </Title>
      <Title dos="fade-up" className={styles.title__}>
        tu wok
      </Title>
      <Subtitle dos="fade-up">¡Ármalo como más te gusta!</Subtitle>
      <div className={styles.row}>
        <div className={styles.circleRed}>$129</div>
        <div className={styles.box}>
          <div className={styles.circleYellow}>1</div>
          <div className={styles.boxTitle}>Selecciona tu base</div>
          <div className={styles.note}>elige 1</div>
          <div className={styles.li}>
            <BsCircle />
            Arroz
          </div>
          <div className={styles.li}>
            <BsCircle />
            Fusilli
          </div>
          <div className={styles.li}>
            <BsCircle />
            Tallarín
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.box}>
          <div className={styles.circleYellow}>2</div>
          <div className={styles.boxTitle}>Vegetales</div>
          <div className={styles.note}>elige hasta 4</div>
          <div className={styles.grid}>
            <div>
              <div className={styles.li}>
                <BsCircle />
                Zanahoria
              </div>
              <div className={styles.li}>
                <BsCircle />
                Brócoli
              </div>
              <div className={styles.li}>
                <BsCircle />
                Cebolla
              </div>
            </div>
            <div>
              <div className={styles.li}>
                <BsCircle />
                Champiñón
              </div>
              <div className={styles.li}>
                <BsCircle />
                Pimiento
              </div>
              <div className={styles.li}>
                <BsCircle />
                Ejote
              </div>
            </div>
            <div>
              <div className={styles.li}>
                <BsCircle />
                Elote
              </div>
              <div className={styles.li}>
                <BsCircle />
                Apio
              </div>
              <div className={styles.li}>
                <BsCircle />
                Chiles toreados
              </div>
            </div>
          </div>
          <div className={styles.li_right}>vegetal extra +$10</div>
        </div>
      </div>
      <div className={styles.double}>
        <div className={`${styles.row} ${styles.special_row}`}>
          <div className={styles.box}>
            <div className={styles.circleYellow}>3</div>
            <div className={styles.boxTitle}>proteínas</div>
            <div className={styles.note}>elige hasta 2</div>
            <div className={styles.li}>
              <BsCircle />
              Pollo
            </div>
            <div className={styles.li}>
              <BsCircle />
              Huevo
            </div>
            <div className={styles.li}>
              <BsCircle />
              Res
            </div>
            <div className={styles.li}>
              <BsCircle />
              Tocino
            </div>
            <div className={styles.li_right}>proteína extra +$15</div>
            <div className={styles.boxTitle2}>extras</div>
            <div className={styles.li}>
              <BsCircle />
              Pollo crispy +$15
            </div>
            <div className={styles.li}>
              <BsCircle />
              Camarón frito +$40
            </div>
          </div>
        </div>
        <div className={`${styles.row} ${styles.special_row2}`}>
          <div className={styles.box_special}>
            <div className={styles.circleYellow_special}>4</div>
            <div className={styles.boxTitle}>Salsas</div>
            <div className={styles.note}>elige hasta 2</div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              BBQ
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Chipotle
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Teriyaki
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Mushroom
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Soya limón
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Hunan (agridulce)
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Habanero
            </div>
            <div className={styles.li_sepcial}>
              <BsCircle />
              Crema
            </div>
            <div className={styles.li_right}>salsa extra +$12</div>
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.box}>
          <div className={styles.circleYellow}>5</div>
          <div className={styles.boxTitle}>remátalo</div>
          <div className={styles.note}>con lo que quieras</div>
          <div className={styles.grid2}>
            <div>
              <div className={styles.li}>
                <BsCircle />
                Queso mozzarella +$18
              </div>
              <div className={styles.li}>
                <BsCircle />
                Nuez de la india +$18 +$18
              </div>
            </div>
            <div>
              <div className={styles.li}>
                <BsCircle />
                Tampico +$18
              </div>
              <div className={styles.li}>
                <BsCircle />
                Ajonjolí mixto
              </div>
              <div className={styles.li}>
                <BsCircle />
                Cacahuate
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Base;
