import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Title from "../../components/Title";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/RockNWokNeon_02_On.png';
import rwImg1x2 from '../../assets/static/RockNWokNeon_02_On@2x.png';
import rwImg2 from '../../assets/static/RockNWokNeon_01_Off.png';
import rwImg2x2 from '../../assets/static/RockNWokNeon_01_Off@2x.png';

const About = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.about}>
      <div className={styles.container}>
        <Title dos="fade-left" className={styles.title}>
          THE WAY
        </Title>
        <Title dos="fade-right" className={styles.margin}>
          WE ROCK
        </Title>
        <p className={styles.p} data-aos="fade-up">
          <span>Rock n’ Wok</span> es comida oriental y comida china hecha a nuestra manera,
          que inicia en el año 2010 con la firme convicción de evolucionar y
          trabajar para trascender juntos. Durante 10 años Rock & Wok ha buscado
          ofrecer un modelo de restaurante donde todas las personas están
          organizadas y aman su trabajo.
        </p>
        <p className={styles.p} data-aos="fade-up">
          En todo lo que hacemos, buscamos
          <span>
            {" "}
            expresar nuestra personalidad y crear algo que nos haga sentir
            orgullosos.
          </span>{" "}
          Queremos crecer y llegar más lejos.
        </p>
        <div className={styles.buttons} data-aos="fade-up">
          <Link to="/menu">
            <Button>Ver menú</Button>
          </Link>
        </div>
        <div className={`${styles.image} text-flicker-in-glow `}>
          <img
            src={rwImg1}
            srcset={rwImg1x2 + ' 2x'}
            height="565"
            width="643"
            alt="Rock and wok neon on"
            className={`${styles.neon1}`}
            style={{
              opacity: `${
                window.screen.width < 680
                  ? offsetY < 780
                    ? 0
                    : offsetY > 780 && offsetY < 820
                    ? 0.5
                    : 1
                  : offsetY < 1513
                  ? 0
                  : offsetY > 1513 && offsetY < 1650
                  ? 0.5
                  : 1
              }`,
            }}
          />
          <img   
            src={rwImg2}
            srcset={rwImg2x2 + ' 2x'}
            height="565"
            width="643"
            alt="Rock and wok neon off"
            className={styles.neon2}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
