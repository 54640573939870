import React, { useEffect } from "react";
import styles from "./styles.module.css";
import Button from "../../components/Button";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Aos from "aos";
import "aos/dist/aos.css";

import rwImg1 from '../../assets/static/GalletaPostres.jpg';
import rwImg1x2 from '../../assets/static/GalletaPostres@2x.jpg';

import rwImg2 from '../../assets/static/RockinSweet_Bebidas_01_TedeJazmin.png';
import rwImg2x2 from '../../assets/static/RockinSweet_Bebidas_01_TedeJazmin@2x.png';

import rwImg3 from '../../assets/static/RockinSweet_Bebidas_02_AguaMaracuya.png';
import rwImg3x2 from '../../assets/static/RockinSweet_Bebidas_02_AguaMaracuya@2x.png';

import rwImg4 from '../../assets/static/RockinSweet_Bebidas_03_JamaicaCanela.png';
import rwImg4x2 from '../../assets/static/RockinSweet_Bebidas_03_JamaicaCanela@2x.png';

import rwImg5 from '../../assets/static/RockinSweet_Bebidas_04_PepinoLimon.png';
import rwImg5x2 from '../../assets/static/RockinSweet_Bebidas_04_PepinoLimon@2x.png';

const Base = () => {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.float}>
        <img src="/BadgeOk.svg" width="102" height="151" alt="Icono Ok" />

        <Button className={styles.button__float}>
          <a
            href="https://pide.rockandwok.com/rocknwok/new"
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            ordenar en línea
          </a>
        </Button>
      </div>
      <Title dos="fade-up" className={styles.title__}>
        ROCKIN’
      </Title>
      <Title dos="fade-up" className={styles.title}>
        SWEET
      </Title>
      <div className={styles.box} data-aos="fade-up">
        <h6 className={styles.s1}>Brownie</h6>
        <p className={styles.s2} style={{ marginTop: 0, fontSize: "14px" }}>
          Con helado de vainilla <span className={styles.s1}>$56</span>.
        </p>
        <p className={styles.s2}>1 pieza.</p>
      </div>
      <div className={styles.box2} data-aos="fade-up">
        <h6 className={styles.s1}>Galleta</h6>
        <p className={styles.s2} style={{ marginTop: "0" }}>
          Galleta con chispas <br /> de chocolate.
        </p>
        <p className={styles.s2}>1 pieza.</p>
      </div>
      <div className={styles.cookie} data-aos="fade-up" data-aos-delay="1500">
        <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
          width="900"
          height="513"
          alt="Galletas Postres"
        />

        <Button className={styles.button__float}>
          <a
            href="https://pide.rockandwok.com/rocknwok/new"
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            ordenar en línea
          </a>
        </Button>
      </div>
      <Title dos="fade-up" className={styles.title}>
        stay
      </Title>
      <Title dos="fade-up" className={styles.title__red}>
        fresh
      </Title>
      <Subtitle dos="fade-up" className={styles.subtitle}>
        Aguas <span className={styles.subtitle_bold}>100% naturales</span>{" "}
        preparadas <br /> con fruta de temporada.
      </Subtitle>
      <div className={styles.grid}>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
              width="130"
              height="185"
              alt="Rockin Sweet Bebidas Té de Jazmín"
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Té de jazmín
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Nuestra deliciosa agua de la casa elaborada con infusión de jazmín,
            un toque de limón y endulzante.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg3}
              srcset={rwImg3x2 + ' 2x'}
              width="130"
              height="185"
              alt="Rockin Sweet Bebidas Agua de Maracuya"
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Maracuyá
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Agua fresca de maracuyá.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg4}
              srcset={rwImg4x2 + ' 2x'}
              width="130"
              height="185"
              alt="Rockin Sweet Bebidas Jamaica Canela"
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Jamaica canela
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Agua fresca de jamaica con canela.
          </p>
        </div>
        <div className={styles.embla__slide__img}>
          <div className={styles.embla__slide__image} data-aos="fade-up">
            <img
              src={rwImg5}
              srcset={rwImg5x2 + ' 2x'}
              width="130"
              height="185"
              alt="Rockin Sweet Bebidas Pepino Limón"
            />

            <Button className={styles.button__float}>
              <a
                href="https://pide.rockandwok.com/rocknwok/new"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ordenar en línea
              </a>
            </Button>
          </div>
          <h5 data-aos="fade-up" className={styles.h5}>
            Pepino limón
          </h5>
          <p data-aos="fade-up" className={styles.p}>
            Agua fresca de pepino con limón.
          </p>
        </div>
      </div>
      <div className={styles.bebidas} data-aos="fade-up">
        <h6 className={styles.s1_}>Bebidas frías</h6>
        <div className={styles.boxB}>
          <p className={styles.s3}>Agua del día (500 ml)</p>
          <p className={styles.s4}>$28</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Agua del día (1 lt)</p>
          <p className={styles.s4}>$39</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Té de jazmín (500 ml)</p>
          <p className={styles.s4}>$28</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Té de jazmín (1 lt)</p>
          <p className={styles.s4}>$39</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Agua ciel (600 ml)</p>
          <p className={styles.s4}>$25</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Refresco (355 ml)</p>
          <p className={styles.s4}>$28</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>San Pellegrino (330 ml)</p>
          <p className={styles.s4}>$39</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Coca Cherry (355 ml)</p>
          <p className={styles.s4}>$39</p>
        </div>
      </div>
      <div className={styles.bebidas} data-aos="fade-up">
        <h6 className={styles.s1_}>Cerveza</h6>
        <div className={styles.boxB}>
          <div>
            <p className={styles.s4}>Nacional (325 ml)</p>
            <p className={styles.s3}>
              Indio, Tecate, Tecate Light, <br />
              XX Lager, XX Ambar
            </p>
          </div>
          <p className={styles.s4}>$35</p>
        </div>
        <div className={styles.boxB}>
          <div>
            <p className={styles.s4}>Premium (250 ml)</p>
            <p className={styles.s3}>
              Bohemia clara, Bohemia <br />
              obscura, Heineken
            </p>
          </div>
          <p className={styles.s4}>$40</p>
        </div>
        <div className={styles.boxB}>
          <div>
            <p className={styles.s4}>Importada (355 ml)</p>
            <p className={styles.s3}>Heineken, Amstel Ultra.</p>
          </div>
          <p className={styles.s4}>$45</p>
        </div>
      </div>
      <div className={styles.bebidas} data-aos="fade-up">
        <h6 className={styles.s1_}>Bebidas calientes</h6>
        <div className={styles.boxB}>
          <p className={styles.s3}>Espresso</p>
          <p className={styles.s4}>$26</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Espresso doble</p>
          <p className={styles.s4}>$35</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Americano</p>
          <p className={styles.s4}>$30</p>
        </div>
        <div className={styles.boxB}>
          <p className={styles.s3}>Capuccino</p>
          <p className={styles.s4}>$42</p>
        </div>
      </div>
    </div>
  );
};

export default Base;
