import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.css";

import useEmblaCarousel from "embla-carousel-react";

import rwImg1 from '../../assets/static/slider_1.jpg';
import rwImg1x2 from '../../assets/static/slider_1@2x.jpg';

import rwImg2 from '../../assets/static/slider_2.jpg';
import rwImg2x2 from '../../assets/static/slider_2@2x.jpg';

import rwImg3 from '../../assets/static/slider_3.jpg';
import rwImg3x2 from '../../assets/static/slider_3@2x.jpg';

import rwImg4 from '../../assets/static/slider_4.jpg';
import rwImg4x2 from '../../assets/static/slider_4@2x.jpg';

import rwImg5 from '../../assets/static/slider_5.jpg';
import rwImg5x2 from '../../assets/static/slider_5@2x.jpg';

import rwImg6 from '../../assets/static/slider_6.jpg';
import rwImg6x2 from '../../assets/static/slider_6@2x.jpg';

import rwImg7 from '../../assets/static/slider_7.jpg';
import rwImg7x2 from '../../assets/static/slider_7@2x.jpg';

const Slider = () => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "center",
    loop: true,
    skipSnaps: false,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );
  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={styles.embla}>
        <div className={styles.embla__viewport} ref={viewportRef}>
          <div className={styles.embla__container}>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
              src={rwImg1}
              srcset={rwImg1x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
                    src={rwImg6}
                    srcset={rwImg6x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
              src={rwImg2}
              srcset={rwImg2x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
                    src={rwImg3}
                    srcset={rwImg3x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
                    src={rwImg4}
                    srcset={rwImg4x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
                    src={rwImg5}
                    srcset={rwImg5x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__inner}>
                <div className={styles.embla__slide__img}>
                  <img
                    src={rwImg7}
                    srcset={rwImg7x2 + ' 2x'}
                    height="612"
                    width="915"
                    alt="Rock and Wok Slide"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.arrows}>
          <div
            className={styles.arrow}
            onClick={scrollPrev}
            enabled={prevBtnEnabled}
          >
            <img
              src="/Flecha_Slider_Atras.svg"
              height="19"
              width="40"
              alt="Flecha Slider Atrás"
            />
          </div>
          <div className={styles.dots}>
            {scrollSnaps.map((_, index) => {
              if (index === selectedIndex) {
                return (
                  <div className={styles.circle}>
                    <img
                      key={index}
                      onClick={() => scrollTo(index)}
                      src="/SliderBotonesNuevos.svg"
                      alt="Slider Botones"
                    />
                  </div>
                );
              } else {
                return (
                  <img
                    className={styles.circle2}
                    key={index}
                    onClick={() => scrollTo(index)}
                    src="/SliderBotonesNuevos.svg"
                    alt="Slider Botones"
                  />
                );
              }
            })}
          </div>
          <div
            className={styles.arrow}
            onClick={scrollNext}
            enabled={nextBtnEnabled}
          >
            <img
              src="/Flecha_Slider_Siguiente.svg"
              height="19"
              width="40"
              alt="Flecha Slider Siguiente"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
